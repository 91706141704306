import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Seo } from "components/contentful/seo/Seo";
import { Hero } from "components/contentful/hero/Hero";
import { MediaTextFeatures } from "components/contentful/media-text-features/MediaTextFeatures";
import { BgGradient } from "components/bg-svgs/gradients/BgGradient";
import { WrapperBgs } from "components/container/WrapperBgs";
import { BgLine1 } from "components/bg-svgs/lines/home/bg-line-1/BgLine1";
import { UIContext } from "context/ui";
import { BillboardTextContentful } from "components/contentful/billboard-text/BillboardText";
import gsap from "gsap";
import DrawSVGPlugin from "../utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitText from "../utils/SplitText/SplitText";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { VideoItem } from "components/video-item/VideoItem";
import { QuoteCarousel } from "components/quote-carousel/QuoteCarousel";
import { BgLine2 } from "components/bg-svgs/lines/homepage-connect/bg-line-2/BgLine2";
import { BgLine3 } from "components/bg-svgs/lines/homepage-connect/bg-line-3/BgLine3";
import { MediaTextContentful } from "components/contentful/media-text/MediaText";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CSSRulePlugin);

export default ({ data }: any) => {
  const { isMobile, isDesktop } = useContext(UIContext);
  const { contentfulData } = data;
  const seo = contentfulData.seo;
  return (
    <>
      {seo && <Seo seo={seo} />}
      <BgGradient />

      <WrapperBgs overflowMobile={true} style={{ marginBottom: 100 }}>
        <Hero
          clearPaddingBottom={true}
          hero={{ ...contentfulData.hero, clearPaddingBottom: true }}
        />
        <VideoItem autoplay={true} loop={true} item={contentfulData.body[0]} />
        {isDesktop && <BgLine1 />}
      </WrapperBgs>

      <WrapperBgs>
        {isDesktop && <BgLine2 />}
        <BillboardTextContentful
          style={{ padding: isMobile && 0 }}
          billboardText={contentfulData.body[1]}
        />
      </WrapperBgs>

      <WrapperBgs>
      <MediaTextFeatures
          mediaTextFeatures={contentfulData.body[2]}
          titleInside={true}
          allFeaturesLeft={true}
          noIcons={true}
          imgNoMarginTop={true}
          hasParallax={false}
          swapTitleAndContent={true}
        />
        <MediaTextFeatures
          mediaTextFeatures={contentfulData.body[3]}
          titleInside={true}
          allFeaturesLeft={true}
          noIcons={true}
          imgNoMarginTop={true}
          hasParallax={false}
          swapTitleAndContent={true}
        />
        {isDesktop && <BgLine3 />}
      </WrapperBgs>

      <WrapperBgs>
        <VideoItem
          style={{
            borderRadius: isDesktop ? "70px 70px 0 0" : "40px 40px 0 0",
            marginBottom: isDesktop ? "250px" : "50px",
            marginTop: isDesktop ? "100px" : "30px"
          }}
          item={contentfulData.body[4]}
          spaceVideo={true}
        />
      </WrapperBgs>

      <WrapperBgs>
        <QuoteCarousel list={contentfulData.body[6]} />
        {isDesktop && <BgLine2 />}
      </WrapperBgs>

      <WrapperBgs>
        <MediaTextContentful
          mediaText={contentfulData.body[7]}
          layout="column"
          hasParallax={false}
        />
      </WrapperBgs>
    </>
  );
};

export const query = graphql`
  {
    contentfulData: contentfulPage(name: { eq: "Rides" }) {
      name
      seo {
        description
        title
        twitterImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        facebookImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        no_index
        no_follow
        name
      }
      body {
        ... on ContentfulBillboard {
          id
          title
          text {
            raw
          }
        }
        ... on ContentfulMediaTextFeatures {
          id
          title
          text {
            raw
          }
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          alignImageLeft
          items {
            description {
              raw
            }
            icon {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
          }
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulMediaTextFeatures {
          id
          title
          text {
            raw
          }
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          alignImageLeft
          items {
            description {
              raw
            }
            icon {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
          }
          image {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        
        ... on ContentfulList {
          id
          title
          text {
            raw
          }
          cta {
            label
            href
          }
          items {
            title
            text {
              raw
            }
            quote {
              author
              text {
                raw
              }
              avatar {
                description
                file {
                  details {
                    image {
                      width
                      height
                    }
                  }
                  url
                }
              }
            }
            image {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              description
            }
            video {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
          image {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            description
          }
        }
        ... on ContentfulQuote {
          id
          author
          text {
            raw
          }
        }
        ... on ContentfulMediaText {
          id
          videoUrl
          showPlayButton
          autoplay
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          title
          text {
            raw
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          cta {
            href
            label
          }
          imageMobile {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulVideo {
          id
          videoUrl
          video {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }

        ... on ContentfulMediaText {
          id
          videoUrl
          showPlayButton
          autoplay
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          title
          text {
            raw
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }

          sectionBackground {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
            description
          }
          
          cta {
            href
            label
          }
          imageMobile {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        title
        text {
          raw
        }
      }
    }
  }
`;
